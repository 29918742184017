import { fetchWithAuth, fetchWithOutAuth } from "../interceptor";
import {
  APIBatchResponse,
  APIResponseGetInvoice,
  APIResponseGetInvoices,
  APIResponseLineItem,
  APIResponseUpdateInvoice,
  InvoiceResponse,
} from "./types";

export const getInvoices = async (
  filters: InvoiceFiltersT & { entityId?: number }
): Promise<APIResponseGetInvoices> => {
  const {
    type,
    searchQuery,
    status,
    sortOrder,
    earlyPay,
    searchCompany,
    invoiceIdentifier,
    poNumber,
    issuedFromDate,
    issuedToDate,
    dueFromDate,
    dueToDate,
    paidFromDate,
    paidToDate,
    approvedAtFromDate,
    approvedAtToDate,
    finalPaidFromDate,
    finalPaidToDate,
    balancePaidFromDate,
    balancePaidToDate,
    uniqueIdentifiers,
    pageNumber,
    limit = 9,
    sortBy,
    entityId,
    tag,
    creditType,
  } = filters;

  let apiURL = "/invoices?";
  let targetCompanyId = "";
  let targetContactId = "";

  if (searchCompany?.value !== undefined) {
    if (searchCompany.value.includes("companies:")) {
      targetCompanyId = searchCompany.value.replace("companies:", "");
    } else if (searchCompany.value.includes("contacts:")) {
      targetContactId = searchCompany.value.replace("contacts:", "");
    }
  }

  apiURL += `&page=${pageNumber}`;

  if (limit) apiURL += `&limit=${limit}`;
  if (status) apiURL += `&status=${status}`;
  if (type) apiURL += `&type=${type}`;
  if (earlyPay === true) apiURL += `&early_pay=true`;
  if (earlyPay === false) apiURL += `&early_pay=false`;
  if (searchQuery) apiURL += `&q=${searchQuery}`;
  if (uniqueIdentifiers) apiURL += `&uniqueIdentifiers=${uniqueIdentifiers}`;
  if (invoiceIdentifier) apiURL += `&invoiceIdentifier=${invoiceIdentifier}`;
  if (poNumber) apiURL += `&poNumber=${poNumber}`;
  if (dueFromDate) apiURL += `&dueFromDate=${dueFromDate}`;
  if (dueToDate) apiURL += `&dueToDate=${dueToDate}`;
  if (paidFromDate) apiURL += `&paidFromDate=${paidFromDate}`;
  if (paidToDate) apiURL += `&paidToDate=${paidToDate}`;
  if (issuedToDate) apiURL += `&issuedToDate=${issuedToDate}`;
  if (issuedFromDate) apiURL += `&issuedFromDate=${issuedFromDate}`;
  if (approvedAtToDate) apiURL += `&approvedAtToDate=${approvedAtToDate}`;
  if (approvedAtFromDate) apiURL += `&approvedAtFromDate=${approvedAtFromDate}`;
  if (tag) apiURL += `&tag=${tag.value}`;
  if (creditType) apiURL += `&creditType=${creditType.value}`;

  // This filter uses 3 states:

  if (entityId) apiURL += `&entityId=${entityId}`;

  if (finalPaidFromDate) apiURL += `&finalPaidFromDate=${finalPaidFromDate}`;
  if (finalPaidToDate) apiURL += `&finalPaidToDate=${finalPaidToDate}`;

  if (balancePaidFromDate)
    apiURL += `&balancePaidFromDate=${balancePaidFromDate}`;
  if (balancePaidToDate) apiURL += `&balancePaidToDate=${balancePaidToDate}`;

  if (targetCompanyId) apiURL += `&targetCompanyId=${targetCompanyId}`;
  if (targetContactId) apiURL += `&targetContactId=${targetContactId}`;
  if (sortOrder) apiURL += `&ascending=${sortOrder}`;
  if (sortBy) apiURL += `&sortBy=${sortBy}`;

  return fetchWithAuth(apiURL, { method: "GET" });
};

export const exportInvoices = async (
  filters: InvoiceFiltersT,
  columns: string[]
) => {
  const {
    type,
    searchQuery,
    status,
    sortOrder,
    earlyPay,
    searchCompany,
    invoiceIdentifier,
    poNumber,
    issuedFromDate,
    issuedToDate,
    dueFromDate,
    dueToDate,
    paidFromDate,
    paidToDate,
    finalPaidFromDate,
    finalPaidToDate,
    balancePaidFromDate,
    balancePaidToDate,
    uniqueIdentifiers,
    limit = 9,
    sortBy,
  } = filters;

  let apiURL = "/invoices/export?";
  let targetCompanyId = "";
  let targetContactId = "";

  if (searchCompany?.value !== undefined) {
    if (searchCompany.value.includes("companies:")) {
      targetCompanyId = searchCompany.value.replace("companies:", "");
    } else if (searchCompany.value.includes("contacts:")) {
      targetContactId = searchCompany.value.replace("contacts:", "");
    }
  }

  if (limit) apiURL += `&limit=${limit}`;
  if (status) apiURL += `&status=${status}`;
  if (type) apiURL += `&type=${type}`;
  if (earlyPay === true) apiURL += `&early_pay=true`;
  if (earlyPay === false) apiURL += `&early_pay=false`;
  if (searchQuery) apiURL += `&q=${searchQuery}`;
  if (uniqueIdentifiers) apiURL += `&uniqueIdentifiers=${uniqueIdentifiers}`;
  if (invoiceIdentifier) apiURL += `&invoiceIdentifier=${invoiceIdentifier}`;
  if (poNumber) apiURL += `&poNumber=${poNumber}`;
  if (dueFromDate) apiURL += `&dueFromDate=${dueFromDate}`;
  if (dueToDate) apiURL += `&dueToDate=${dueToDate}`;
  if (paidFromDate) apiURL += `&paidFromDate=${paidFromDate}`;
  if (paidToDate) apiURL += `&paidToDate=${paidToDate}`;
  if (issuedToDate) apiURL += `&issuedToDate=${issuedToDate}`;
  if (issuedFromDate) apiURL += `&issuedFromDate=${issuedFromDate}`;
  if (balancePaidFromDate)
    apiURL += `&balancePaidFromDate=${balancePaidFromDate}`;
  if (balancePaidToDate) apiURL += `&balancePaidToDate=${balancePaidToDate}`;
  if (finalPaidToDate) apiURL += `&finalPaidToDate=${finalPaidToDate}`;
  if (finalPaidFromDate) apiURL += `&finalPaidFromDate=${finalPaidFromDate}`;
  if (targetCompanyId) apiURL += `&targetCompanyId=${targetCompanyId}`;
  if (targetContactId) apiURL += `&targetContactId=${targetContactId}`;
  if (sortOrder) apiURL += `&ascending=${sortOrder}`;
  if (sortBy) apiURL += `&sortBy=${sortBy}`;

  apiURL += `&columns=${columns.toString()}`;

  return fetchWithAuth(apiURL, { method: "GET" });
};

export const fetchInvoice = async (props: {
  uniqueInvoiceId: string;
  invoiceToken?: string; // Unique token for public viewing
  isPublic?: boolean;
}): Promise<APIResponseGetInvoice> => {
  const { uniqueInvoiceId, invoiceToken, isPublic } = props;

  if (invoiceToken || isPublic) {
    return fetchWithOutAuth(
      `/invoices/public/${uniqueInvoiceId}?token=${invoiceToken}`,
      { method: "GET" }
    );
  } else {
    return fetchWithAuth(`/invoices/${uniqueInvoiceId}`, { method: "GET" });
  }
};

export const updateInvoice = async (
  invoice: InvoiceType
): Promise<APIResponseUpdateInvoice> => {
  return fetchWithAuth(`/invoices/${invoice.id}`, {
    method: "PUT",
    body: JSON.stringify({ invoice }),
  });
};

export const createInvoice = async (
  amount?: number | string | null
): Promise<InvoiceResponse> => {
  return fetchWithAuth(`/invoices`, {
    method: "POST",
    body: JSON.stringify({
      invoice: { amount },
    }),
  });
};

export const approveInvoice = async (props: {
  id: number;
  approved: boolean;
  approved_amount: number;
  estimated_payment_date: Date;
  holdback_release_date?: Date;
  message?: string;
  credits: { creditId: number; amount: number }[];
  digitalSignature?: {
    firstName: string;
    lastName: string;
    title: string;
    email: string;
    phone: string;
  };
}): Promise<InvoiceResponse> => {
  const {
    id,
    approved,
    approved_amount,
    estimated_payment_date,
    holdback_release_date,
    message,
    digitalSignature,
    credits,
  } = props;

  const url = `/invoices/${digitalSignature ? "public/" : ""}${id}/${
    approved ? "approve" : "decline"
  }`;

  const body = {
    method: "POST",
    body: JSON.stringify({
      approval: {
        comment: message,
        approved_amount,
        holdback_release_date,
        estimated_payment_date,
        digitalSignature,
        credits,
      },
    }),
  };

  const content = digitalSignature
    ? await fetchWithOutAuth(url, body)
    : await fetchWithAuth(url, body);

  const metadata = {
    title: content?.invoice?.title,
    early_pay: content?.invoice?.early_pay,
    unique_identifier: content?.invoice?.unique_identifier,
    reference_number: content?.invoice?.po_number,
    invoice_identifier:
      content?.invoice?.invoice_identifier || content?.invoice?.id,
    totals: content?.invoice?.totals?.total,
    approved_amount:
      content?.invoice?.totals?.approved_amount ||
      content?.invoice?.totals?.total,
    status: content?.invoice?.status,
    seller:
      content?.invoice?.Company?.name ||
      content?.invoice?.ContactInvoiceBills?.[0]?.Contact?.company_name,
    buyer:
      content?.invoice?.payingCompany?.name ||
      content?.invoice?.ContactInvoices?.[0]?.Contact?.company_name,
    approver_first_name: content?.invoice?.InvoiceApprovals
      ? content?.invoice?.InvoiceApprovals[0]?.User?.first_name
      : "",
    comment: message,
    uploaded: content?.invoice?.Assets && content?.invoice?.Assets?.length > 0,
    version: process.env.NEXT_PUBLIC_APP_VERSION,
  };

  window &&
    window.Intercom &&
    window.Intercom(
      "trackEvent",
      `bill-${approved ? "approval" : "decline"}`,
      metadata
    );

  return content;
};

export const archiveInvoice = async (props: {
  invoiceId: number;
}): Promise<InvoiceResponse> => {
  const { invoiceId } = props;
  return fetchWithAuth(`/invoices/${invoiceId}`, { method: "DELETE" });
};

export const claimBill = async (props: {
  invoiceId: number;
}): Promise<InvoiceResponse> => {
  const { invoiceId } = props;
  return fetchWithAuth(`/invoices/${invoiceId}/claim`, { method: "POST" });
};

export const sendInvoice = async (props: {
  id: number;
  contact_id?: number | null;
  email?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  company_name?: string | null;
}): Promise<InvoiceResponse> => {
  const { id, contact_id, email, first_name, last_name, company_name } = props;

  return fetchWithAuth(`/invoices/${id}/send`, {
    method: "POST",
    body: JSON.stringify({
      contact_id,
      email,
      first_name,
      last_name,
      company_name,
    }),
  });
};

export const createInvoiceLineItem = async (props: {
  id: number;
  lineItem: InvoiceLineItemType;
}): Promise<APIResponseLineItem> => {
  const { id, lineItem } = props;
  return fetchWithAuth(`/invoices/${id}/items`, {
    method: "POST",
    body: JSON.stringify({ invoiceLineItem: lineItem }),
  });
};

export const updateInvoiceLineItem = async (props: {
  id: number;
  lineItem: InvoiceLineItemType;
}): Promise<APIResponseLineItem> => {
  const { id, lineItem } = props;
  return fetchWithAuth(`/invoices/${id}/items/${lineItem.id}`, {
    method: "PUT",
    body: JSON.stringify({ invoiceLineItem: lineItem }),
  });
};

export const archiveInvoiceLineItem = async (props: {
  id: number;
  itemId: number;
}): Promise<APIResponseLineItem> => {
  const { id, itemId } = props;
  return fetchWithAuth(`/invoices/${id}/items/${itemId}`, {
    method: "DELETE",
  });
};

export const syncInvoice = async (props: {
  id: number;
  account: { value: string; label: string };
  tax: { value: string; label: string };
  postedDate: string | Date;
}): Promise<APIResponse> => {
  const { id, account, tax, postedDate } = props;
  return fetchWithAuth(`/invoices/${id}/sync`, {
    method: "POST",
    body: JSON.stringify({
      account,
      tax,
      postedDate,
    }),
  });
};

export const batchEarlyPayRequest = async (
  invoices: InvoiceType[]
): Promise<APIBatchResponse> => {
  return fetchWithAuth(`/invoices/batch/requestEarlyPay`, {
    method: "POST",
    body: JSON.stringify({
      invoices: invoices,
    }),
  });
};

export const batchApproveInvoices = async (
  decision: BillDecisionT,
  approvals: {
    invoiceId: number;
    comment: string;
    approved_amount: number;
    holdback_release_date: string | Date;
    estimated_payment_date: string | Date;
    creditApplications: { creditId: number; amount: number }[];
  }[]
): Promise<APIBatchResponse> => {
  return fetchWithAuth(`/invoices/batch/${decision}`, {
    method: "POST",
    body: JSON.stringify({
      approvals,
    }),
  });
};

export const confirmFees = async (props: {
  invoiceId: number;
}): Promise<InvoiceResponse> => {
  const { invoiceId } = props;
  return fetchWithAuth(`/invoices/${invoiceId}/confirmFees`, {
    method: "POST",
  });
};
